import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import parse from "html-react-parser";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import { Link } from "gatsby";

const query = graphql`
  {
    allDocx {
      edges {
        node {
          content
        }
      }
    }
  }
`;

const TermsofUse = () => {
  const data = useStaticQuery(query);
  return (
    <section>
      <Layout>
        <Navbar />
        <div className="page terms">
          <div className="container">
            {/* <div>{parse(data.allDocx.edges[0].node.content)}</div> */}
            <h1 className="title">TERMS OF USE</h1>
            <p>
              Access to this website https://seaeasycapital.com/  is granted by 
              Sea Easy Capital Pte. Ltd. (“SEC”, “we”, “our” or “us”) subject 
              to these Terms of Use and our Privacy Policy. Please read these 
              Terms of Use carefully. By accessing this website (including our 
              platform), you agree to be bound by these Terms of Use and our 
              Privacy Policy. If you do not accept any of these Terms of Use 
              and/or Privacy Policy, please discontinue your access to this 
              website immediately.  

              Please note that while the website is open for public access, the 
              services provided by us through the platform accessible on this 
              website will only be available to registered users of this website. 
            </p>
            <ol>
              <li>
                <h1>Access</h1>
                <ol className="sub-list">
                  <li>
                    All access to and use of this website (including any 
                    submission, posting or any other form of transmission 
                    activity) shall be governed by these Terms of Use. Accessing 
                    this website from territories where its contents are deemed 
                    illegal or unlawful is prohibited. If you choose to access 
                    this website outside of Singapore, you do so of your own 
                    volition and are responsible for complying with local laws 
                    in the territory of access.
                  </li>
                  <li>
                    SEC reserves all rights to modify this website and its contents, 
                    including but not limited to services, products, information, 
                    data, text, graphics, audio, video, links (third party or 
                    otherwise), advertisements, widgets, APIs (Application 
                    Programming Interface) or other items (the “Content”), features 
                    and functionality of this website, to shut down this website and 
                    to deny or restrict access to this website to any particular 
                    person or entity, or to block access from a particular internet 
                    address to this website, at any time and without any prior notice, 
                    without ascribing any reasons whatsoever.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Changes to Terms of Use and Privacy Policy</h1>
                <ul>
                  <li>
                    We may make changes to these Terms of Use and/or the Privacy Policy from time to time and post a revised version on this website, which shall be effective immediately upon such posting. We are under no obligation to separately inform you of any such revision. Your continued access of this website after the revised Terms of Use or Privacy Policy have taken effect will constitute your unconditional acceptance of such revised Terms of Use or Privacy Policy.
                  </li>
                </ul>
              </li>
              <li>
                <h1>No Warranty, Representation or Undertaking </h1>
                <ol className="sub-list">
                  <li>
                    The Content is provided "as is" and on an "as available" basis. SEC gives no representation, warranty or undertaking (as the case may be), express or implied, to anyone of any kind, including: 
                    <ol className="lower-alpha">
                      <li>
                        the availability of this website and the platform for any viewing, transaction or account management purposes and that there will be no interruption or error in any of this website’s feature or function;                         
                      </li>
                      <li>
                        in relation to any title in connection with this website and its Content, or any non-infringement of third party rights;
                      </li>
                      <li>
                        that this website or the servers that make it available will be free of viruses, malware or other harmful components or other malicious, destructive or corrupting code, agent, script, program or macros; 
                      </li>
                      <li>
                        that this website will be compatible with all software and hardware or that it will be secure;
                      </li>
                      <li>
                        that this website is of satisfactory fitness or quality for any general or particular purpose;
                      </li>
                      <li>
                        that the information on this website is up-to-date, complete, true, accurate, non-misleading, reliable, or valid;
                      </li>
                      <li>
                        that any defects and/or errors on this website will be corrected immediately or promptly;
                      </li>
                      <li>
                        that any message, request, instruction or other communication sent via the internet in relation to, or in connection with, the Content will be free from interception, corruption, error, delay or loss; 
                      </li>
                      <li>
                        in relation to the credit worthiness, business or financial situation of any person; or
                      </li>
                      <li>
                        in respect of any content, service, product, material or information contained in any third party link or website. 
                      </li>
                    </ol>
                  </li>
                  <li>
                    Under no circumstances will SEC (or our employees, agents, directors or shareholders) be liable for any Content on this website, including (without limitation) any errors or omissions in any Content, or for any loss or damage of any kind incurred as a result of your use of this website or any Content. 
                  </li>
                  <li>
                    SEC neither assumes nor accepts liability for any loss or damage, (whether direct or indirect), howsoever caused, as a result of or arising from your use or inability to use or access of this website and/or any Content. 
                  </li>
                </ol>
              </li>
              <li>
                <h1>Intellectual Property</h1>
                <ol className="sub-list">
                  <li>
                    The names, images and logos ("Marks") identifying SEC or third parties and their products and services are subject to copyright, design rights and trademarks respectively, of SEC and/or third parties, and all rights to the Marks are expressly reserved by SEC or the relevant third parties. You shall have no right or licence to download, reproduce or use any such Marks or the name of SEC whether for use as a link to any website or otherwise, except with the prior approval of SEC or the relevant third parties. 
                  </li>
                  <li>
                    This website including the Content is protected by copyright and/or other proprietary rights. The Content may include content owned and controlled by third parties and licensed to SEC. All rights under applicable laws are hereby reserved.
                  </li>
                </ol>
              </li>
              <li>
                <h1>External Links and Third Party Applications</h1>
                <ul>
                  <li>
                    This website may contain links to other websites and online resources which are not maintained or operated by SEC. The use of such third party websites and resources is at your own risk. SEC is not responsible for and does not endorse the availability or contents of such third party websites or resources. Such links do not constitute or imply any sponsorship, recommendation or any other relationship between SEC and such third party. SEC shall not be liable for any damages or loss (including any computer viruses, trojan horses, worms or similar items or processes), arising from the availability of contents of those third party websites or resources. We are not and shall not be a party to any transaction between you and the third party website or service. 
                  </li>
                </ul>
              </li>
              <li>
                <h1>Data Protection</h1>
                <ol className="sub-list">
                  <li>
                    Any personal data submitted to SEC whether through this website or otherwise is dealt with according to the Privacy Policy, accessible at <Link to="/privacy-policy">
                    Privacy policy
                  </Link>. 
                  </li>
                  <li>
                    If you provide or have provided any information (including personal data) relating to another person, you warrant and represent to SEC that that you have obtained that person’s consent for the collection, use and disclosure of their information as set out in these Terms of Use and the Privacy Policy.
                  </li>
                </ol>
              </li>
              <li>
                <h1>Disclaimer</h1>
                <ol className="sub-list">
                  <li>
                    This website has not been registered with any regulatory authority. Returns on any transaction are not guaranteed including the possible loss of the entire principal amount invested in the transaction. Any invoice factoring arrangement remains subject to further approval and legal documentation.  
                  </li>
                  <li>
                    This website may refer to certain features of an invoice factoring arrangement but does not purport to contain a complete description of all material terms or of the terms which may differ from those summarised in this website. Accordingly, this website and the Content is qualified in its entirety by the more detailed documentation relating to the invoice factoring arrangement. Information in this website is based upon certain assumptions and analysis of information available as at the date of the Content uploading and reflects prevailing conditions and SEC’s views as of such date, all of which are subject to change at any time without notice. Although reasonable care has been taken to ensure the accuracy of information contained in this website, SEC makes no representation or warranty of any kind, express, implied or statutory, and shall not be responsible or liable for the accuracy or completeness of the information.  
                  </li>
                  <li>
                    Please note that the graphs, charts, formulae or other devices set out or referred to in this website cannot, in and of themselves, be used to determine and will not assist any person in deciding whether and/or when to factor an invoice or purchase a factored invoice. You should note that any past performance of any investment, company, entity or SEC mentioned in this website, and any prediction, projection or forecast on the economy, stock market, bond market or the economic trends of the markets is not necessarily indicative of the future or likely performance of any investment, company, entity or SEC or the economy, stock market, bond market or economic trends of the markets. Nothing in this website shall constitute a representation or give rise to any implication that there has not been or that there will not be any change affecting the invoice factoring arrangement. Employees of SEC shall not be held liable for any decision or action taken based on the views expressed or information contained in this website.  
                  </li>
                  <li>
                    You should consider carefully the risks of purchasing a factored invoice and may wish to seek advice from a financial and/or legal adviser before making any commitment. Should you choose not to seek advice from a financial and/or legal adviser, you should consider carefully whether the transaction is suitable for you. Similarly, companies seeking to factor its invoices should seek advice and carefully consider if the product offering/services is suitable for you. 
                  </li>
                  <li>
                    This website and its Content are strictly for general information only and does not constitute, and shall not be deemed to constitute, an offer, solicitation, advice or recommendation to buy or sell any investment or invest in any company, or to grant or procure any financing. This website should not be construed as accounting, legal, regulatory, tax, financial or other advice. Investments are not obligations of, deposits in, or guaranteed or insured by SEC, or any of its subsidiary, associate, affiliate or distributors.  
                  </li>
                </ol>
              </li>
              <li>
                <h1>Liability</h1>
                <ol className="sub-list">
                  <li>
                    To the maximum extent permitted by law, SEC hereby expressly excludes all conditions, warranties and other terms that might otherwise be implied by law into these Terms of Use. 
                  </li>
                  <li>
                    Without prejudice to any other limitation of liability by SEC provided herein, each of SEC, and all of its subsidiaries, affiliated companies, directors, officers, agents, partners and employees shall not be liable to any person, including, but not limited to, any users of this website, for any direct, indirect, punitive, incidental, special, consequential damages, losses, expenses, liabilities under any causes of action or any damages whatsoever, including, without limitation, damages for loss of use or data, loss of opportunity, loss of goodwill, loss of profits (whether, but not limited to, revenue or anticipated profits) or losses to third parties, arising out of or in any way connected with any use of, inability to use or reliance on any information provided on, this website, whether based on contract, tort, strict liability or otherwise, even if SEC or its agents or employees had been advised of the possibility of such damages or losses. 
                  </li>
                </ol>
              </li>
              <li>
                <h1>Indemnity</h1>
                <ol className="sub-list">
                  <li>
                    Without prejudice to the other provisions herein, you hereby undertake to indemnify and keep each of SEC, and all of its subsidiaries, affiliated companies, directors, officers, agents, partners and employees indemnified at all times from and against all actions, proceedings, costs, claims, expenses (including all legal costs on a full indemnity basis), demands, liabilities, losses (whether direct, indirect or consequential) and damages (whether in tort, contract or otherwise) whatsoever and howsoever arising, including without limitation claims made by third parties, regulatory penalties and claims for defamation, infringement of intellectual property rights, death, bodily injury, wrongful use of computers, unauthorised or illegal access to computers (including but not limited to hacking), property damage or pecuniary losses which they may sustain, incur, suffer or pay arising out of, in connection with or pursuant to the access to and/or the use of this website by you, whether or not such access or use was authorised or whether it was due to any act or omission on its part, the breach of these Terms of Use by you, the violation by you of any rights of another person or entity or the breach by you of any statutory requirement, duty or law. 
                  </li>
                  <li>
                    We may, if necessary, participate in the defence of any claim or action and any negotiations for settlement. No settlement shall be made without our prior written approval. We reserve the right, at our own expense and on notice to you, to assume exclusive defence and control of any claim and action. 
                  </li>
                </ol>
              </li>
              <li>
                <h1>Your Use of Our Website</h1>
                <ol className="sub-list">
                  <li>
                    By accessing this website, you agree that you will not:
                    <ol className="lower-alpha">
                      <li>    
                        use this website in any way which is unlawful, illegal, fraudulent, threatening, harassing, vulgar, obscene, abusive or harmful, or otherwise objectionable, or in connection with any such purpose or activity;  
                      </li>
                      <li>
                        use this website to defame, abuse, harass, stalk, threaten or otherwise violate the rights of others or SEC, including without limitation others' privacy rights or rights of publicity;  
                      </li>
                      <li>
                        impersonate any person or entity, falsely state or otherwise misrepresent your affiliation with any person or entity in connection with this website or express or imply that SEC endorses any statement you make;  
                      </li>
                      <li>
                        interfere with or disrupt the operation of this website or the servers or networks used to make this website available or violate any requirements, procedures, policies or regulations of such networks;  
                      </li>
                      <li>
                        license, sublicense, sell, resell, transfer, assign or otherwise commercially exploit or make available to any third party the Content in any way; 
                      </li>
                      <li>
                        modify or make derivative works based on the Content, reverse engineer, alter, disassemble, or decompile the website and the Content, or access the underlying software for any reason; 
                      </li>
                      <li>
                        access or search, or attempt to access or search, the Content by any means (automated or otherwise) other than through the currently available, published interface which is provided by us; 
                      </li>
                      <li>
                        access or attempt to access or tamper with the non-publicly available parts of the website; 
                      </li>
                      <li>
                        forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the website and the services offered on the website to send altered, deceptive or false source-identifying information; 
                      </li>
                      <li>
                        transmit or otherwise make available in connection with this website any virus, worm, files scripts, agents, programs or other computer code that is harmful or invasive or may or is intended to damage the operation of, or to monitor the use of, any hardware, software, or equipment;  
                      </li>
                      <li>
                        infringe any intellectual property rights, or remove any copyright, trade mark or other proprietary rights notice from this website;  
                      </li>
                      <li>
                        use any information found on this website to canvass or solicit any person or entice any person away from the employment of, investment with, seeking funding from or any other commercial relationship with SEC or this website;  
                      </li>
                      <li>
                        reproduce all or any part of the website or the Content, or use the website or the Contents to construct a database of any kind nor may you store the website (in its entirety or in any part) in databases for access by you or any third party or distribute any database websites containing all or part of the website; 
                      </li> 
                      <li>
                        show, re-transmit, re-distribute, disclose or copy, or rely upon, in whole or in part, any part of this website or its Contents for whatever purpose, without the prior consent of SEC; or 
                      </li>
                      <li>
                        insert a hyperlink to this website or any part thereof or “mirror” any Content on any other server, website or web page.                    
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <h1>Use of Services</h1>
                <ul>
                  <li>
                    Notwithstanding anything in this website, your eligibility for any product or service on this website is subject to acceptance by SEC. 
                  </li>
                </ul>
              </li>
              <li>
                <h1>Severability</h1>
                <ul>
                  <li>
                    If any provision of these Terms of Use or part thereof is rendered void, illegal or unenforceable by any legislation to which it is subject, it shall be rendered void, illegal or unenforceable to that extent and no further. 
                  </li>
                </ul>
              </li>
              <li>
                <h1>Waiver</h1>
                <ul>
                  <li>
                    The rights of each party under these Terms of Use may be exercised as often as necessary, are cumulative and not exclusive of rights or remedies provided by law and may be waived only in writing and specifically. The delay in the exercise or non-exercise of any right shall not be deemed as a waiver of that right. 
                  </li>
                </ul>
              </li>
              <li>
                <h1>Third Parties</h1>
                <ul>
                  <li>
                    A person not a party to any transaction between you and SEC has no right under these Terms of Use under the Contract (Rights of Third Parties) Act of Singapore.
                  </li>
                </ul>
              </li>
              <li>
                <h1>Governing Law & Jurisdiction</h1>
                <ul>
                  <li>
                    These Terms of Use are governed by the laws of Singapore and you irrevocably submit to the exclusive jurisdiction of the courts of Singapore in order to settle or resolve any dispute which may arise out of or in connection with these Terms of Use. In connection therewith, you irrevocably waive, to the fullest extent permitted by law, any objection that any of them may now or hereafter have to the laying by SEC of the venue of any such dispute in any such court or that any such dispute brought in any such court has been brought in an inconvenient forum. 
                  </li>
                </ul>
              </li>
            </ol>
          </div>
        </div>
      <Footer />
      </Layout>
    </section>    
  );
};

export default TermsofUse;
